/* add css styles here (optional) */

/* .test {
  display  : inline-block;
  margin   : 2em auto;
  border   : 2px solid #000;
  font-size: 20em;
} */

/* ############################################################################################################# */
/* MODULE_BLOC_GAUCHE_DROITE_2 */


.moduleBlocGaucheDroite2 figure,
.moduleBlocGaucheDroite2 section {
  display: block;
}

.moduleBlocGaucheDroite2 .container{
  position      : relative;
  max-width     : 1440px;
  text-align    : right;
  margin        : 0 auto;
  padding-top   : 30px;
  padding-bottom: 30px;
}

.moduleBlocGaucheDroite2 .gauche {
  text-align    : inherit !important;
}


.moduleBlocGaucheDroite2 .container :first-child {
  padding-top: 0;
}

.moduleBlocGaucheDroite2 .container figure {
  position           : absolute;
  display            : inline-block;
  background-size    : cover;
  background-position: 50%;
  background-repeat  : no-repeat;
}

.moduleBlocGaucheDroite2 .container .figureGauche {
  left               : 579px;
}

.moduleBlocGaucheDroite2 .container .figureDroite {
  left               : 138px;
}

.moduleBlocGaucheDroite2 .container figure img {
  width: 100%;
}

.moduleBlocGaucheDroite2 .container .moduleBlocGaucheDroite2Contenu {
  position        : relative;
  display         : inline-block;
  background-color: #fff;
  text-align      : center;
  vertical-align  : top;
  width           : 650px;
  min-height      : 510px;
  margin-top      : 50px;
}

.moduleBlocGaucheDroite2 .container .moduleBlocGaucheDroite2Contenu .boxCenter {
  display   : table;
  width     : 100%;
  height    : 390px;
  min-height: 390px;
  padding   : 10px 70px 70px 70px;
}

.moduleBlocGaucheDroite2 .container .moduleBlocGaucheDroite2Contenu .boxCenter .contenu-h2,
.moduleBlocGaucheDroite2 .container .moduleBlocGaucheDroite2Contenu .boxCenter .contenu-h2 {
  font-size: 1.5em;
}

@media (min-width: 0) and (max-width: 1439px) {
  .moduleBlocGaucheDroite2 .container {
    padding: 30px 73px;
  }

  .moduleBlocGaucheDroite2 .container .moduleBlocGaucheDroite2Contenu {
    min-height: 430px;
    width     : 50%;
    margin-top: 30px;
  }

  .moduleBlocGaucheDroite2 .container .moduleBlocGaucheDroite2Contenu .boxCenter {
    height    : 350px;
    min-height: 350px;
    padding   : 0px 50px 50px 50px;
  }

  .moduleBlocGaucheDroite2 .container figure {
    
    width: 43%;
  }

  .moduleBlocGaucheDroite2 .container .figureGauche {
    left : 45.5%;
  }
  
  .moduleBlocGaucheDroite2 .container .figureDroite {
    left : 11.5%;
  }
}

@media (min-width: 0) and (max-width: 1023px) {
  .moduleBlocGaucheDroite2 .container {
    padding: 30px 77px;
  }

  .moduleBlocGaucheDroite2 .container figure {
 
    width: 70%;
  }
  .moduleBlocGaucheDroite2 .container .figureGauche {
    left : 194px;
  }
  
  .moduleBlocGaucheDroite2 .container .figureDroite {
    left : 77px;
  }

  .moduleBlocGaucheDroite2 .container .moduleBlocGaucheDroite2Contenu {
    margin-top: 46%;
    width     : 87.6%;
  }

  .moduleBlocGaucheDroite2 .container .moduleBlocGaucheDroite2Contenu .boxCenter {
    padding: 0px 40px 40px 40px;
  }
}

@media (min-width: 0) and (max-width: 767px) {
  .moduleBlocGaucheDroite2 {
    padding-top   : 20px;
    padding-bottom: 20px;
  }

  .moduleBlocGaucheDroite2 .container {
    padding: 20px;
  }

  .moduleBlocGaucheDroite2 .container figure {

    width: calc(100% - 40px);
  }

  .moduleBlocGaucheDroite2 .container .figureGauche {
    left : 35px;
  }
  
  .moduleBlocGaucheDroite2 .container .figureDroite {
    left : 0;
  }

  .moduleBlocGaucheDroite2 .container .moduleBlocGaucheDroite2Contenu {
    width     : 100%;
    margin-top: 57.5%;
    min-height: 310px;
  }

  .moduleBlocGaucheDroite2 .container .moduleBlocGaucheDroite2Contenu .boxCenter {
    height    : 230px;
    min-height: 230px;
    padding   : 0px 15px 50px 15px !important;
  }
}



/* ############################################################################################################# */
/* MODULE_CAROUSEL */

/* .slick-next::before {
  border-left  : 21px solid #555555;
  border-right : 0px solid transparent;
  border-top   : 19px solid transparent;
  border-bottom: 20px solid transparent;
  font-size    : 0px;
}

.slick-rev::before {
  border-right : 21px solid #555555;
  border-left  : 0px solid transparent;
  border-top   : 19px solid transparent;
  border-bottom: 20px solid transparent;
  transform    : rotate(180deg) !important;
  font-size    : 0px; 
 } */

/* ############################################################################################################# */